<template>
  <div
    class="vert-container"
    :style="$vuetify.breakpoint.smAndDown ? 'max-height:24px' : 'max-height:40px'"
  >
    <v-btn
      class="article-move-btn"
      :height="$vuetify.breakpoint.smAndDown ? '10px' : '18px'"
      :width="$vuetify.breakpoint.smAndDown ? '24px' : '40px'"
      color="blue-grey lighten-2"
      dark
      @click="$emit('moveBtnClicked', true)"
    >
      <v-icon
        :size="$vuetify.breakpoint.smAndDown ? '16px' : '24px'"
      >
        mdi-chevron-up
      </v-icon>
    </v-btn>

    <v-btn
      class="article-move-btn"
      :height="$vuetify.breakpoint.smAndDown ? '10px' : '18px'"
      :width="$vuetify.breakpoint.smAndDown ? '24px' : '40px'"
      color="blue-grey lighten-2"
      dark
      @click="$emit('moveBtnClicked', false)"
    >
      <v-icon
        :size="$vuetify.breakpoint.smAndDown ? '16px' : '24px'"
      >
        mdi-chevron-down
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="css" scoped>
.vert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.article-move-btn {
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
