<template>
  <div>
    <EditBtn
      v-if="has_permission_edit"
      :editing="editing"
      @editBtnClicked="emitEditBtn()"
    />
    <div
      v-if="editing && has_permission_edit"
      class="tool-buttons"
    >
      <ConfirmBtn
        v-if="has_permission_edit"
        @confirmBtnClicked="emitConfirmBtn()"
      />
      <CancelBtn
        v-if="has_permission_edit"
        @cancelBtnClicked="emitCancelBtn()"
      />
    </div>
    <div v-if="editing && has_permission_edit"  class="h1-fake textblock-grow-wrap">
      <textarea
        class="h1-fake textblock-area"
        :value="title"
        onInput="this.parentNode.dataset.replicatedValue = this.value"
        onfocusin="this.parentNode.dataset.replicatedValue = this.value"
        :id="block_id"
        @keydown.enter="emitConfirmBtn()"
      />
    </div>
    <h1 v-else>
      {{ title }}
    </h1>
  </div>
</template>

<script>
import EditBtn from '@/components/Articles/Buttons/EditBtn.component.vue';
import ConfirmBtn from '@/components/Articles/Buttons/ConfirmBtn.component.vue';
import CancelBtn from '@/components/Articles/Buttons/CancelBtn.component.vue';

export default {
  props: [
    'title',
    'has_permission_edit',
    'editing',
    'block_id',
  ],

  components: {
    EditBtn,
    ConfirmBtn,
    CancelBtn,
  },

  methods: {
    emitEditBtn() {
        this.$emit('editBtnClicked');
    },

    emitConfirmBtn() {
      let value = {};
      let input = document.getElementById(`${this.block_id}`).value.trim();

      if(input != '' && input != this.title) {
        value.title = input;
      }

      this.$emit('confirmBtnClicked', { value: value });
    },

    emitCancelBtn() {
      this.$emit('cancelBtnClicked')
    },
  },

  updated() {
    if(this.editing) {
      let d = document.getElementById(`${this.block_id}`);
      d.parentNode.dataset.replicatedValue = d.value;
    }
  },
}
</script>

<style lang="css" scoped>
h1 {
  text-align: center;
}
.h1-fake {
  text-align: justify;
  font-weight: lighter;
  font-size: var(--h1-font-size);
  color: white;
}
.tool-buttons {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
}

/* self growing textarea */
/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
.textblock-grow-wrap {
  display: grid;
}
.textblock-grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.textblock-grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.textblock-grow-wrap > textarea,
.textblock-grow-wrap::after {
  outline-color: grey;
  outline-style: dashed;
  outline-width: thin;
  padding: 0px;
  font: inherit;

  grid-area: 1 / 1 / 2 / 2;
}
</style>
