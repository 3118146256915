<template>
  <v-btn
    class="article-edit-btn"
    :height="$vuetify.breakpoint.smAndDown ? '24px' : '40px'"
    :width="$vuetify.breakpoint.smAndDown ? '24px' : '40px'"
    :color="editing ? 'green darken-1' : 'blue darken-1'"
    :disabled="editing"
    dark
    @click="$emit('editBtnClicked')"
  >
    <v-icon
      :size="$vuetify.breakpoint.smAndDown ? '16px' : '24px'"
    >
      mdi-pencil
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: [
    'editing'
  ],
}
</script>

<style lang="css" scoped>
.article-edit-btn {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-150%, 0%);
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
