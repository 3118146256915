<template>
  <div>
    <v-text-field
      class="media-youtube-link"
      label="YouTube URL"
      background-color="#242424"
      dark
      v-model="url_input"
      @input="updateUrl()"
      @change="videoUrlChanged()"
    />
  </div>
</template>

<script>
export default {
  props: [
    'url',
  ],

  data: () => ({
    url_input: '',
    url_output: '',
  }),

  methods: {
    /*
    EXAMPLES
    ========
              https://youtu.be/dyiOqxC017o?t=36
    /:        dyiOqxC017o?t=36
    watch?v=: dyiOqxC017o?t=36
    ?:        dyiOqxC017o
    &:        dyiOqxC017o

              https://www.youtube.com/watch?v=SBeYzoQPbu8&list=PLbALbm1g5VzAqShkgKwo0NIVkwV9bZE8t&index=4
    /:        /watch?v=SBeYzoQPbu8&list=PLbALbm1g5VzAqShkgKwo0NIVkwV9bZE8t&index=4
    watch?v=: SBeYzoQPbu8&list=PLbALbm1g5VzAqShkgKwo0NIVkwV9bZE8t&index=4
    ?:        SBeYzoQPbu8&list=PLbALbm1g5VzAqShkgKwo0NIVkwV9bZE8t&index=4
    &:        SBeYzoQPbu8

    */
    updateUrl() {
      let url = this.url_input;
      let arr;

      arr = url.split('/');
      url = arr[arr.length-1];

      arr = url.split('watch?v=');
      url = (arr.length > 1) ? arr[1] : arr[0];

      arr = url.split('?');
      url = arr[0];

      arr = url.split('&');
      url = arr[0];

      this.url_output = url;
    },

    videoUrlChanged() {
      this.$emit('videoUrlChanged', {
        action: 'emit_changed_video_url',
        value: `https://www.youtube.com/embed/${this.url_output}`
      })
    },
  },

  mounted() {
    this.url_input = (this.url != 'none') ? this.url : '';
  },
}
</script>

<style lang="css" scoped>
.media-youtube-link {
  max-width: 300px;
}
.v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
</style>
