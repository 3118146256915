<template>
  <div
    class="article-view-wrapper"
    v-if="article.published == 'public' || hasOneOfPermissions([
        'TextBlock CREATE', 'MediaBlock CREATE', 'TextBlock UPDATE', 'MediaBlock UPDATE',
        'Article READ', 'Article UPDATE', 'TextBlock DELETE', 'MediaBlock DELETE',
      ])"
    :style="$vuetify.breakpoint.smAndDown ? 'padding: 5%' : ''"
  >
    <div
      class="article-top-box"
      v-if="hasPermission('Article UPDATE')"
    >
      <div
        class="article-top-box-container"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'flex-direction:column; margin:20px;'
            : 'flex-direction:row'"
      >
        <div class="article-top-thumbnail">
          <ImageSelect
            @selectImage="selectImage($event.value)"
          />
          <Thumbnail
            :title="article_title"
            :publish_date="getArticleDate(article)"
            :authors="article_authors"
            :thumbnail="thumbnail"
            :reference="{}"
            style="max-width:100%"
          />
        </div>
        <div class="article-top-input-fields">
          <v-text-field
            label="Titel"
            background-color="#242424"
            dark
            v-model="article_title"
          />
          <div class="horiz gap">
            <v-select
              label="Autoren"
              v-model="article_authors"
              :items="potential_authors"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '300' }"
              return-object
              background-color="#242424"
              multiple
              chips
              dark
            />
          </div>
          <div class="article-top-btns">
            <v-btn
              dark
              outlined
              @click="showPublishArticleOverlay(article)"
            >
              {{ (article.published == 'public') ? 'Artikel verstecken' : 'Artikel publizieren'}}
            </v-btn>
          </div>
          <div class="article-top-btns">
            <v-btn
              color="red darken-1"
              outlined
              @click="showDeleteArticleOverlay(article.category_id, article.id)"
            >
              <v-icon
                dark
                small
                left
              >
                mdi-alert
              </v-icon>
              Löschen
            </v-btn>

            <v-btn
              color="red darken-1"
              outlined
              @click="discardArticleClicked();"
            >
              Abbrechen
            </v-btn>

            <v-btn
              dark
              outlined
              @click="updateArticle()"
            >
              Änderungen speichern
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Article Title -->
    <div class="article-block">
      <TitleBlock
        :title="article.title"
        :has_permission_edit="hasPermission('TextBlock UPDATE')"
        :editing="'title-block' === current_block"
        :block_id="'title-block'"
        @editBtnClicked="current_block=`title-block`"
        @confirmBtnClicked="updateHeadline($event)"
        @cancelBtnClicked="current_block = ''"
      />
    </div>

    <div class="article-line article-head-space" />
    <!-- Author, Published Date -->
    <div class="article-subheader">
      <h2
        class="left"
        v-if="authors"
        :style="$vuetify.breakpoint.smAndDown ? 'font-size: 9pt' : ''"
        v-html="authors"
      />
      <h3 class="right" :style="$vuetify.breakpoint.smAndDown ? 'font-size: 11pt' : ''">
        {{ getArticleDate(article) }}
      </h3>
    </div>
    <!-- Actual Content -->
    <CreateBlock
        v-if="hasAllOfPermissions(['TextBlock CREATE', 'MediaBlock CREATE'])"
        :category_id="article.category_id"
        :article_id="article.id"
        @createTextBlock="createTextBlock(0)"
        @createMediaBlockImage="createMediaBlockImage(0)"
        @createMediaBlockVideo="createMediaBlockVideo(0)"
    />
    <div
      v-for="(block, i) in article.blocks"
      :key="`article-block${i + 1}`"
      :class="(hasAllOfPermissions(['TextBlock CREATE', 'MediaBlock CREATE'])) ? '' : 'article-space'"
    >
      <div class="article-block">
        <!-- Texte -->
        <TextBlock
          v-if="block.type==='textblocks'"
          :textblock="getUObject({ type: block.type, id: block.id})"
          :has_permission_edit="hasAllOfPermissions(['TextBlock UPDATE', 'TextBlock DELETE'])"
          :editing="`text-block${block.id}`===current_block"
          :block_id="`text-block${block.id}`"
          :is_selected="current_block===`text-block${block.id}`"
          @editBtnClicked="current_block=`text-block${block.id}`"
          @moveBtnClicked="moveBlock(block, $event, 'textblocks')"
          @confirmBtnClicked="updateTextBlock(block.id, $event)"
          @cancelBtnClicked="current_block = ''"
          @deleteBtnClicked="showDeleteBlockOverlay(block, 'textblocks');"
        />
        <!-- @deleteBtnClicked="deleteBlock(block, 'textblocks')" -->
        <!-- Images, Videos, etc. -->
        <MediaBlock
          v-else
          :mediablock="getUObject({ type: block.type, id: block.id })"
          :editing="`media-block${block.id}`===current_block"
          :has_permission_edit="hasAllOfPermissions(['MediaBlock UPDATE', 'MediaBlock DELETE'])"
          :block_id="`media-block${block.id}`"
          :is_selected="current_block===`media-block${block.id}`"
          @editBtnClicked="current_block=`media-block${block.id}`"
          @moveBtnClicked="moveBlock(block, $event, 'mediablocks')"
          @confirmBtnClicked="updateMediaBlock(block.id, $event)"
          @cancelBtnClicked="current_block = ''"
          @deleteBtnClicked="showDeleteBlockOverlay(block, 'mediablocks');"
        />
        <!-- @deleteBtnClicked="deleteBlock(block, 'mediablocks')" -->
      </div>
      <CreateBlock
        v-if="hasAllOfPermissions(['TextBlock CREATE', 'MediaBlock CREATE'])"
        :category_id="article.category_id"
        :article_id="article.id"
        @createTextBlock="createTextBlock(i + 1)"
        @createMediaBlockImage="createMediaBlockImage(i + 1)"
        @createMediaBlockVideo="createMediaBlockVideo(i + 1)"
      />
    </div>
    <div :class="(hasAllOfPermissions(['TextBlock CREATE', 'MediaBlock CREATE'])) ? '' : 'article-space'">
      <div
        class="article-hashtag-container article-block"
        :style="(hasAllOfPermissions(['TextBlock CREATE', 'MediaBlock CREATE'])) ? 'padding-top:10px;' : ''"
      >
        <div
          v-for="(hashtag, i) in hashtags"
          :key='`hashtag${i}`'
        >
          <Hashtag
            :hashtag="hashtag"
            :has_permission_edit="hasPermission('Article UPDATE')"
            :is_template="hashtag.is_template"
            :is_show_error="is_show_hashtag_error"
            @confirmHashtag="confirmHashtag(hashtag, $event.value.tag)"
            @deleteHashtag="deleteHashtag(hashtag)"
            @resetShowError="is_show_hashtag_error = false;"
          />
        </div>
        <v-btn
          v-if="hasPermission('Article UPDATE')"
          fab
          height="36px"
          width="36px"
          rounded
          color="blue darken-1"
          dark
          @click="createHashtag()"
        >
          <v-icon
            small
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </div>
    <WarningOverlay
      v-if="is_show_warning_overlay"
      :warning_text="warning.text"
      :warning_icon="warning.icon"
      @cancel="resetWarning()"
      @confirm="confirmWarning()"
    />
  </div>
</template>

<script>
import TitleBlock from '@/components/Articles/TitleBlock.component.vue';
import TextBlock from '@/components/Articles/TextBlock.component.vue';
import MediaBlock from '@/components/Articles/MediaBlock.component.vue';
import Hashtag from '@/components/Articles/Hashtag.component.vue';
import CreateBlock from '@/components/Articles/CreateBlock.component.vue';
import Thumbnail from '@/components/Thumbnail.component.vue';
import ImageSelect from '@/components/ImageSelect.component.vue';
import { ArticleDate } from '@/mixins/ArticleDate.mixin.js';
import { Ultra } from '@/mixins/Ultra.mixin.js';
import { Knowledge } from '@/mixins/Knowledge.mixin.js';
import { WarningOverlayMixin } from '@/mixins/WarningOverlayMixin.mixin.js';
import { ImageSelectMixin } from '@/mixins/ImageSelectMixin.mixin.js';
import { Navigation } from '@/mixins/Navigation.mixin.js';

export default {
  components: {
    TitleBlock,
    TextBlock,
    MediaBlock,
    Hashtag,
    CreateBlock,
    Thumbnail,
    ImageSelect,
  },

  mixins: [
    ArticleDate, Ultra, Knowledge,
    WarningOverlayMixin, ImageSelectMixin,
    Navigation,
  ],

  props: [
    'article',
    'potential_authors',
  ],

  computed: {
    hashtags() {
      let arr = [];
      if(!this.article.hashtags) {
        return arr;
      }

      for(let i = 0; i < this.article.hashtags.length; i++) {
        arr.push(this.article.hashtags[i])
      }

      for(let i = 0; i < this.temp_hashtags.length; i++) {
        arr.push(this.temp_hashtags[i]);
      }

      return arr;
    },

    authors() {
      let s = '<span>';
      if(this.article.authors.length >= 1) {
        s += `<span class="author-name">${ this.article.authors[0].first_name } ${ this.article.authors[0].last_name }</span>`;
      }

      for(let i = 1; i < this.article.authors.length; i++) {
        s += `, <span class="author-name">${ this.article.authors[i].first_name } ${ this.article.authors[i].last_name }</span>`;
      }
      s += '</span>'
      return s;
    },

    thumbnail() {
      if(this.new_image.local_url){
        return this.new_image.local_url;
      }
      if(this.article.featured_image) {
        return this.article.featured_image;
      }

      return 'https://takting-images.s3.us-east-2.amazonaws.com/articles/default-thumbnail.png';
    },

    is_article_changed() {
      let is_changed =
        this.article.title != this.article_title ||
        JSON.stringify(
          this.getMatchedSimplePotentialAuthors(this.article.authors))
          .localeCompare(JSON.stringify(this.article_authors)
        ) != 0 ||
        this.new_image.local_url
      ;
      return is_changed;
    },
  },

  data: () => ({
    current_block: '',
    temp_hashtag_id: 0,
    temp_hashtags: [],
    is_show_hashtag_error: false,

    article_title: '',
    article_authors: '',
    article_thumbnail: '',
  }),

  methods: {
    updateHeadline(obj) {
      this.current_block = '';
      this.updateUObject({
          url: [
            { categories: this.article.category_id },
            { articles: this.article.id },
          ],
          update: obj.value,
      })
    },

    createTextBlock(index) {
      this.createUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { textblocks: '' },
          { showWithEntitiesNormalized: '' },
        ],
        u_object: {
          text: '[Neuer Textblock]',
          order_index: index,
        },
      });
    },

    updateTextBlock(block_id, obj) {
      this.current_block = '';
      this.updateUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { textblocks: block_id },
        ],
        update: obj.value,
      })
    },

    createMediaBlockImage(index) {
      this.createUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { mediablocks: '' },
          { showWithEntitiesNormalized: '' },
        ],
        u_object: {
          type: 'image',
          description: '',
          order_index: index,
        },
      });
    },

    createMediaBlockVideo(index) {
      this.createUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { mediablocks: '' },
          { showWithEntitiesNormalized: '' },
        ],
        u_object: {
          type: 'youtube',
          description: '',
          order_index: index,
        },
      });
    },

    updateMediaBlock(block_id, obj) {
      if(obj.type === 'image') {
        this.updateMediaBlockImage(block_id, obj);
      }
      if(obj.type === 'youtube') {
        this.updateMediaBlockVideo(block_id, obj);
      }
    },

    updateMediaBlockImage(block_id, obj) {
      this.current_block = '';
      this.updateUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { mediablocks: block_id },
        ],
        update: obj.value,
      }).then(() => {
        new Promise((resolve) => {
          if(obj.img.is_upload) { // if a new thumbnail was set
            this.uploadImage({
                url: [
                  { categories: this.article.category_id },
                  { articles: this.article.id },
                  { mediablocks: block_id },
                ],
                url_suffix: '/imageupload?show=showWithEntitiesNormalized',
                image: obj.img.file,
                update: { key: 'url', value_suffix: 'mediablocks[0].url' },
            }).then(() => { resolve(); })
          } else {
            resolve();
          }
        });
      });
    },

    updateMediaBlockVideo(block_id, obj) {
      this.current_block = '';
      this.updateUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { mediablocks: block_id },
        ],
        update: obj.value,
      })
    },

    deleteBlock(block, key) {
      this.current_block = '';
      this.deleteUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { [key]: block.id },
        ],
      })
      ;
    },

    moveBlock(block, is_up, key) {
      let index = this.article.blocks.findIndex((element) => element.id === block.id);
      if((index <= 0 && is_up) || (index >= this.article.blocks.length - 1) && !is_up) {
        return;
      }
      let new_order_index = (is_up) ? index - 1 : index + 1;
      this.updateUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
          { [key]: block.id },
        ],
        update: { order_index: new_order_index },
        is_up: is_up,
      });
    },

    createHashtag() {
      this.temp_hashtag_id--;
      this.temp_hashtags.push({
        id: this.temp_hashtag_id,
        tag: '',
        is_template: true,
      });
    },

    confirmHashtag(hashtag, tag) {
      if(!tag) {
        return;
      }

      if(Object.values(this.hashtags).find(element => element.tag === tag)) {
        this.is_show_hashtag_error = true;
        return;
      }

      hashtag.is_template = false;
      hashtag.tag = tag;

      this.updateUObject({
        url: [
          { categories: this.article.category_id },
          { articles: this.article.id },
        ],
        update: {
          ['add#hashtags']: [hashtag],
          ['remove#hashtags']: [],
         },
      });
    },

    deleteHashtag(hashtag) {
      if(hashtag.is_template) {
        let index = this.temp_hashtags.indexOf(hashtag);
        this.temp_hashtags.splice(index, 1);
      }
      else {
        let is_deleted = false;
        let hashtag_id = -1;

        let index = this.temp_hashtags.indexOf(hashtag);
        if(index != -1) {
          this.temp_hashtags.splice(index, 1);
          is_deleted = true;
        }
        else {
          hashtag_id = hashtag.id;
        }

        this.updateUObject({
          url: [
            { categories: this.article.category_id },
            { articles: this.article.id },
          ],
          update: {
            ['add#hashtags']: [],
            ['remove#hashtags']: [hashtag],
           },
        }).then(() => {
          if(!is_deleted) {
            this.cleanHashtagArray({ hashtag_id: hashtag_id, article_id: this.article.id });
          }
        });
      }
    },

    updateArticle() {
        let update = {};
        if(this.article.title != this.article_title.trim()) {
          update.title = this.article_title.trim();
        }

        let curr_a_ids = this.article.authors.map(author => author.user_id);
        let edit_a_ids = this.article_authors.map(author => author.user_id);
        if(JSON.stringify(curr_a_ids).localeCompare(JSON.stringify(edit_a_ids)) != 0) {
          let new_authors = [];
          let removed_authors = [];
          let i = 0;
          let j = 0;
          for(; i < curr_a_ids.length && j < edit_a_ids.length; i++, j++) {
            if(curr_a_ids[i] != edit_a_ids[j]) {
              if(!curr_a_ids.includes(edit_a_ids[j]) || removed_authors.includes(edit_a_ids[j])) { // if edit_a_ids[j] is a new author or has been removed previously
                // we need to discard all following authors and add them in as in edit_a_ids[j] and following
                for(; i < curr_a_ids.length; i++) {
                  removed_authors.push(curr_a_ids[i]);
                }
                for(; j < edit_a_ids.length; j++) {
                  new_authors.push(edit_a_ids[j]);
                }
                // loop breaks because i and j are at their max
              }
              else { // the author is in curr_a_ids and in edit_a_ids
                // we need to remove all authors between the last matching author and curr_a_ids[i] == edit_a_ids[j]
                for(; i < curr_a_ids.length; i++) {
                  if(curr_a_ids[i] != edit_a_ids[j]) {
                    removed_authors.push(curr_a_ids[i]);
                  }
                  else {
                    break;
                  }
                }
              }
            }
          }
          if(j < edit_a_ids.length && i >= curr_a_ids.length) { // we haven't looped over all values of edit_a_ids but over all values of curr_a_ids
            for(; j < edit_a_ids.length; j++) {
              new_authors.push(edit_a_ids[j]); // we want to add every remaining value of edit_a_ids in this case
            }
          }
          if(i < curr_a_ids.length && j >= edit_a_ids.length) { // we haven't looped over all values of curr_a_ids but over all values of edit_a_ids
            for(; i < curr_a_ids.length; i++) {
              removed_authors.push(curr_a_ids[i]); // we want to remove every remaining value of curr_a_ids in this case
            }
          }

          let authors_to_remove = this.article.authors.filter(author => removed_authors.includes(author.user_id));
          let remove_authors_user_article_ids = authors_to_remove.map(author => author.id);

          let authors = {};
          if(new_authors.length > 0) {
            authors = { ...authors, ...{ ['add#authors']: new_authors, } };
          }
          if(remove_authors_user_article_ids.length > 0) {
            authors = { ...authors, ...{ ['remove#authors']: remove_authors_user_article_ids, } };
          }
          update = { ...update, ...authors };
        }

        new Promise((resolve) => {
          if(Object.keys(update).length > 0) { // if there are any updates
            this.updateUObject({ // update and then resolve
              url: [
                { categories: this.article.category_id, },
                { articles: this.article.id, },
              ],
              update: update,
            }).then(() => { resolve(); })
          } else {
            resolve(); // if not: instantly resolve
          }
        }).then(() => {
          new Promise((resolve) => {
            if(this.new_image.local_url) { // if a new thumbnail was set
              this.uploadImage({
                  url: [
                    { categories: this.article.category_id },
                    { articles: this.article.id },
                  ],
                  url_suffix: '/imageupload?show=showWithEntitiesNormalized',
                  image: this.new_image.file,
                  update: { key: 'featured_image', value_suffix: 'articles[0].featured_image' },
              }).then(() => { this.resetImageSelection(); resolve(); })
            } else {
              resolve();
            }
          }).then(() => {
            this.requestUObject({
              url: [
                { categories: this.article.category_id, },
                { articles: this.article.id, },
                { showWithEntitiesNormalized: '' },
              ],
            });
          })
        })
      },

    deleteArticle(category_id, article_id) {
      this.deleteUObject({
        url: [
          { categories: category_id },
          { articles: article_id },
        ],
      }).then(() => {
          this.navigateToRoute('Home');
        }
      )
    },

    discardArticleClicked() {
      if(this.is_article_changed) {
        this.showDiscardArticleArticleChangesOverlay();
      }
      else {
        this.setArticle(this.article);
      }
    },

    publishArticle() {
      // TODO: differentiate properly
      let pub = (this.article.published == 'public') ? 'draft' : 'public';
      this.updateUObject({
        url: [
          { categories: this.article.category_id, },
          { articles: this.article.id, },
        ],
        update: { published: pub },
      });
    },

    setArticle() {
      this.article_title = this.article.title;
      this.article_authors = this.getMatchedSimplePotentialAuthors(this.article.authors);
      this.article_thumbnail = this.article.featured_image;
      this.new_image.local_url = '';
    },
  },

  created() {
    if(this.hasPermission('Article UPDATE')) {
      this.updatePotentialAuthors()
      .then(() => {
        this.setArticle();
      });
    }
  },
}
</script>

<style lang="css" scoped>
.article-view-wrapper {
  padding: 0 10%;
}
.article-line {
  border-style: solid;
  border-color: white;
  border-width: 0 0 1px 0;
}
.article-head-space {
  margin: 30px 0 20px 0;
}
.article-subheader {
  display: flex;
  flex-direction: row;
}
.article-edit-line {
  transform: translateY(35px);
  border-style: dashed;
  border-color: grey;
  border-width: 0 0 1px 0;
}
.article-create-block-container {
  position: absolute;
  transform: translateY(-50%);
  display: flex;
  grid-column-gap: 10px;
  justify-content: center;
  width: 100%;
}
.article-space {
  padding: 20px 0;
}
.article-hashtag-container {
  display: flex;
  grid-column-gap: calc(var(--grid-space) * 1px);;
  grid-row-gap: calc(var(--grid-space) * 1px);;
  justify-content: center;
  flex-wrap: wrap;
}
.article-block {
  position: relative; /* for editBtn */
}
.article-top-box {
  width: 100%;
  border-radius: 25px;
  border-style: dashed;
  border-width: 1px;
  border-color: grey;
  margin-bottom: 30px;
}
.article-top-box-container {
  display: flex;
  grid-gap: 50px;
  margin: 50px;
  justify-content: center;
}
.article-top-thumbnail {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.article-top-input-fields {

}
.article-top-btns {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 10px;
}
.v-btn {
  letter-spacing: normal;
  text-transform: none;
}
h2, .author-name {
  font-weight: 300;
  font-size: 12pt;
  font-style: italic;
  width: 50%;
}
h3 {
  font-weight: 300;
  font-size: 14pt;
  width: 50%;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
</style>
