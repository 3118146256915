<template>
  <div>
    <v-btn
      v-if="!is_template"
      rounded
      dark
      @click="search()"
    >
      #{{ hashtag.tag }}
      <v-icon
        v-if="has_permission_edit"
        small
        right
        @click="deleteHashtag()"
      >
        mdi-close
      </v-icon>
    </v-btn>
    <v-sheet
      v-else
      :class="{ 'show-error': is_show_error, 'template-hashtag': true }"
      rounded="pill"
      min-height="36"
      dark
    >
      <div class="inner-template-hashtag">
        <span>#</span>
        <input
          class="input"
          :id="`template_input_${hashtag.id}`"
          v-model="new_tag"
          autofocus
          @keydown.enter="confirmHashtag()"
        />
        <v-icon
          v-if="has_permission_edit"
          small
          right
          @click="confirmHashtag()"
        >
          mdi-check
        </v-icon>
        <v-icon
          v-if="has_permission_edit"
          small
          right
          @click="deleteHashtag()"
        >
          mdi-close
        </v-icon>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { Navigation } from '@/mixins/Navigation.mixin.js';
import { Helpers } from '@/mixins/Helpers.mixin.js';

export default {
  props: [
    'hashtag',
    'has_permission_edit',
    'is_template',
    'is_show_error',
  ],

  mixins: [Navigation, Helpers, ],

  data: () => ({
    new_tag: '',
  }),

  methods: {
    confirmHashtag() {
      let value = {};

      // we only update new information!
      this.new_tag = this.new_tag.replace(/\s/g, '');
      if(this.new_tag != '' && this.new_tag != this.hashtag) {
        value.tag = this.new_tag;
      }

      this.$emit('confirmHashtag', {
        action: 'confirm_hashtag',
        value: value,
      });
    },

    deleteHashtag() {
      this.$emit('deleteHashtag');
    },

    search() {
      this.navigateToSearch(
        this.generateSearchString(
          [this.hashtag.tag]
        )
      );
    },
  },

  mounted() {
    if(this.is_template) {
      document.getElementById(`template_input_${this.hashtag.id}`).focus();
    }
  },

  watch: {
    'is_show_error': function(val) {
      if(this.is_template && val) {
        let self = this;
        setTimeout(function () {
          self.$emit('resetShowError');
        }, 500);
      }
    },
  },
}
</script>

<style lang="css" scoped>
.v-btn {
  letter-spacing: normal;
  text-transform: none;
}
.input {
  border: 1px dashed white;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
  color: white;
  width: 150px;
}
.template-hashtag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  background-color: #1E88E5;
}
.inner-template-hashtag {
  margin: 0 10px;
}

/* .shake {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.color-change {
  animation: color-change 1s;
} */

.show-error {
  animation: shake 200ms infinite, color-change 500ms;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes color-change {
  33% { background-color: #EF5350; }
  67% { background-color: #EF5350; }
  100% { background-color: #1E88E5; }
}
</style>
