<template>
  <div>
    <div v-if="editor && !is_table_mode && is_selected" class="editor-btns">
      <v-btn dark outlined
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <strong>B</strong>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <em>i</em>
      </v-btn>
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <s>S</s>
      </v-btn> -->
      <v-btn dark outlined
        @click="editor.chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <p style="font-family: Courier New">C</p>
      </v-btn>
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().unsetAllMarks().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        clear
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().clearNodes().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        clear nodes
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        paragraph
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        h1
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        h2
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        h3
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        h4
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        h5
      </v-btn> -->
      <v-btn dark outlined
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <strong>&lt;/&gt;</strong>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-format-list-bulleted
        </v-icon>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-format-list-numbered
        </v-icon>
      </v-btn>
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        code block
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        blockquote
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().setHorizontalRule().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        horizontal rule
      </v-btn> -->
      <v-btn dark outlined
        @click="editor.chain().focus().setHardBreak().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon small>
          subdirectory_arrow_left
        </v-icon>
      </v-btn>
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().undo().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        undo
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().redo().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        redo
      </v-btn> -->
      <v-btn dark outlined
        @click="setLink"
        :class="{ 'is-active': editor.isActive('link') }"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon small>
          mdi-link-variant
        </v-icon>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().unsetLink().run()"
        :disabled="!editor.isActive('link')"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon small>
          mdi-link-variant-off
        </v-icon>
      </v-btn>
    </div>
    <div v-if="editor && is_table_mode && is_selected" class="editor-btns">
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <strong>+</strong>
      </v-btn> -->
      <v-btn dark outlined
        @click="editor.chain().focus().addColumnBefore().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-table-column-plus-before
        </v-icon>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().addColumnAfter().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-table-column-plus-after
        </v-icon>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().deleteColumn().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-table-column-remove
        </v-icon>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().addRowBefore().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-table-row-plus-before
        </v-icon>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().addRowAfter().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-table-row-plus-after
        </v-icon>
      </v-btn>
      <v-btn dark outlined
        @click="editor.chain().focus().deleteRow().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          mdi-table-row-remove
        </v-icon>
      </v-btn>
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().deleteTable().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        deleteTable
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().splitCell().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          call_split
        </v-icon>
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().mergeCells().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        <v-icon>
          call_merge
        </v-icon>
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeaderColumn().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        toggleHeaderColumn
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeaderRow().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        toggleHeaderRow
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().toggleHeaderCell().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        toggleHeaderCell
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().mergeOrSplit().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        mergeOrSplit
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().setCellAttribute('colspan', 2).run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        setCellAttribute
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().fixTables().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        fixTables
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().goToNextCell().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        goToNextCell
      </v-btn> -->
      <!-- <v-btn dark outlined
        @click="editor.chain().focus().goToPreviousCell().run()"
        :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
      >
        goToPreviousCell
      </v-btn> -->
    </div>
    <editor-content :editor="editor" @myevent="test()"/>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

// const CustomTableCell = TableCell.extend({
//   addAttributes() {
//       return {
//         ['data-label']: {
//           default: null,
//           // Take the attribute values
//           renderHTML: attributes => {
//             // … and return an object with HTML attributes.
//             return {
//               ['data-label']: `${attributes.data_label}`,
//             }
//           },
//         },
//       }
//     },
// });

export default {
  components: {
    EditorContent,
  },

  props: [
    'initial_content',
    'is_table_mode',
    'is_selected',
    'is_init_confirm',
    'is_init_cancel',
  ],

  data() {
    return {
      editor: null,
      content: '',
    }
  },

  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
  },

  mounted() {
    this.content = this.initial_content;
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Link.configure({
          // HTMLAttributes: {
          //   class: 'my-custom-class',
          // },
          // openOnClick: false,
          // linkOnPaste: false,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell.configure({
          HTMLAttributes: {
            // ['data-label']: 'x',
          },
        }),
        // CustomTableCell,
      ],
      content: `${this.content}`,
      editable: this.is_selected,
    });
    this.editor.commands.focus('end');
  },

  beforeUnmount() {
    this.editor.destroy()
  },

  watch: {
    'is_init_confirm': function(val) {
      if(val) {
        this.$emit('initConfirm', { value: this.editor.getHTML() });
      }
    },

    'is_init_cancel': function(val) {
      if(val) {
        this.editor.commands.setContent(this.initial_content);
        this.$emit('initCancel');
      }
    },

    'is_table_mode': function(val) {
      if(val && this.editor.getHTML() != '') {
        if(this.editor.getHTML().substring(0, 7) !== '<table>') {
          this.editor.commands.setContent('');
          this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
        }
      }
      else if(this.editor.getHTML() != '') {
        if(this.editor.getHTML().substring(0, 7) === '<table>') {
          this.editor.commands.setContent('');
          this.editor.commands.focus('end');
        }
      }
    },

    'is_selected': function(val) {
      this.editor.setOptions({
        editable: val,
      });

      if(val) {
        this.editor.commands.focus('end');
      }
    },
  },
}
</script>

<style lang="scss">
/* Basic editor styles */
.editor-btns {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  padding-bottom: 10px;
}
.editor-btns > .v-btn {
  min-width: 0 !important;
  padding: 0 !important;
  letter-spacing: normal;
  text-transform: none;
}

//table
.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}
.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror {
  //standard
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  //table
  table {
    border-collapse: collapse;
    border: 1px solid #ccc;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #444;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
