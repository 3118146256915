<template>
  <v-btn
    class="article-delete-btn"
    :height="$vuetify.breakpoint.smAndDown ? '24px' : '40px'"
    :width="$vuetify.breakpoint.smAndDown ? '24px' : '40px'"
    color="red darken-3"
    dark
    @click="$emit('deleteBtnClicked')"
  >
    <v-icon
      :size="$vuetify.breakpoint.smAndDown ? '16px' : '24px'"
    >
      mdi-delete
    </v-icon>
  </v-btn>
</template>

<script>
export default {

}
</script>

<style lang="css" scoped>
.article-delete-btn {
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
