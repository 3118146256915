<template>
  <div
    class="media-block-component"
    :id="`media-block${block_id}`"
    :style="(has_permission_edit) ? 'margin-top:15px;' : ''"
  >
    <EditBtn
      v-if="has_permission_edit"
      :editing="editing"
      @editBtnClicked="emitEditBtn()"
    />
    <div
      v-if="editing && has_permission_edit"
      class="tool-buttons"
    >
      <MoveBtn
        v-if="has_permission_edit"
        @moveBtnClicked="emitMoveBtn($event)"
      />
      <ConfirmBtn
        v-if="has_permission_edit"
        @confirmBtnClicked="emitConfirmBtn()"
      />
      <CancelBtn
        v-if="has_permission_edit"
        @cancelBtnClicked="emitCancelBtn()"
      />
      <DeleteBtn
        v-if="has_permission_edit"
        @deleteBtnClicked="emitDeleteBtn()"
      />

      <!-- Image Select Button -->
      <div v-if="has_permission_edit && editing && mediablock.type=='image'">
        <ImageSelect
          @selectImage="selectImage($event.value)"
        />
      </div>

      <!-- URL Input Field -->
      <div v-if="has_permission_edit && editing && mediablock.type=='youtube'">
        <YouTubePurifier
          :url="mediablock.url"
          @videoUrlChanged="updateNewUrl($event.value)"
        />
      </div>
    </div>

    <!-- Image -->
    <div
      v-if="mediablock.type=='image'"
      class="media-block-img-center"
    >
      <div class="media-block-img">
        <!-- Actual Image -->
        <div v-if="mediablock.url != 'none' || new_image.local_url != ''">
          <img
            :src="image"
            :alt="mediablock.description"
            @click="toggleOverlay"
          />
          <!-- Description -->
          <div v-if="editing && has_permission_edit"  class="media-block-description textblock-grow-wrap">
            <textarea
              class="media-block-description textblock-area"
              :id="`description${block_id}`"
              :value="mediablock.description"
              onInput="this.parentNode.dataset.replicatedValue = this.value"
              onfocusin="this.parentNode.dataset.replicatedValue = this.value"
              @change="updateDescription()"
            />
          </div>
          <p v-else class="media-block-description" v-html="mediablock.description"/>
            <!-- {{ mediablock.description }}
          </p> -->
          <!-- Image Clicked Overlay -->
          <v-overlay
            :value="overlay"
            color="black"
            opacity=".85"
          >
            <v-img
              v-click-outside="toggleOverlay"
              class="media-block-overlay-img"
              :src="image"
              :alt="mediablock.description"
              :max-width="max_width"
              :max-height="max_height"
              contain
              @click="toggleOverlay"
              @wheel.prevent.stop
            />
          </v-overlay>
        </div>

        <div
          v-else
          class="image-container-placeholder"
        >
          <v-icon
            color="grey lighten-2"
            size="256"
          >
              mdi-image
          </v-icon>
        </div>
        <p class="hint" v-if="is_show_img_hint">
          Nutze .jpg, .jpeg oder .png als Extension-Typ!
        </p>
      </div>
    </div>

    <!-- YouTube Video -->
    <div
      v-else-if="mediablock.type=='youtube'"
      class="media-block-video"
      :style="$vuetify.breakpoint.smAndDown ? 'padding: 0' : ''"
    >
      <!-- Actual Video -->
      <div v-if="mediablock.url != 'none'">
        <div
          class="video-container"
        >
          <iframe
            class="video"
            :src="mediablock.url"
            allowfullscreen
          />
        </div>
      </div>
      <!-- Video Placeholder -->
      <div
        v-else
        class="video-container-placeholder"
        :style="$vuetify.breakpoint.smAndDown ? 'height: calc(85vw * 0.5625);' : ''"
      >
        <v-icon
          color="grey lighten-2"
          size="256"
        >
            mdi-youtube
        </v-icon>
      </div>
      <!-- Description -->
      <div v-if="editing && has_permission_edit"  class="media-block-description textblock-grow-wrap">
        <textarea
          class="media-block-description textblock-area"
          :id="`description${block_id}`"
          :value="mediablock.description"
          onInput="this.parentNode.dataset.replicatedValue = this.value"
          onfocusin="this.parentNode.dataset.replicatedValue = this.value"
          @change="updateDescription()"
        />
      </div>
      <p v-else class="media-block-description" v-html="mediablock.description">
        <!-- {{ mediablock.description }} -->
      </p>
    </div>

  </div>
</template>

<script>
import EditBtn from '@/components/Articles/Buttons/EditBtn.component.vue';
import MoveBtn from '@/components/Articles/Buttons/MoveBtn.component.vue';
import ConfirmBtn from '@/components/Articles/Buttons/ConfirmBtn.component.vue';
import CancelBtn from '@/components/Articles/Buttons/CancelBtn.component.vue';
import DeleteBtn from '@/components/Articles/Buttons/DeleteBtn.component.vue';
import YouTubePurifier from '@/components/Articles/YouTubePurifier.component.vue';
import ImageSelect from '@/components/ImageSelect.component.vue';
import { ImageSelectMixin } from '@/mixins/ImageSelectMixin.mixin.js';

export default {
  components: {
    EditBtn,
    MoveBtn,
    ConfirmBtn,
    CancelBtn,
    DeleteBtn,
    YouTubePurifier,
    ImageSelect,
  },

  mixins: [ImageSelectMixin, ],

  props: [
    'mediablock',
    'editing',
    'has_permission_edit',
    'block_id',
  ],

  computed: {
    image() {
      if(this.mediablock.type=='image') {
        if(this.new_image.local_url){
          return this.new_image.local_url;
        }
        if(this.mediablock.url) {
          return this.mediablock.url;
        }
      }

      return 'none';
    },

    max_width() {
      return screen.width;
    },

    max_height() {
      return screen.height;
    }
  },

  data: () => ({
      overlay: false,
      new_url: '',
      new_description: '',
  }),

  methods: {
    toggleOverlay() {
      this.overlay = !this.overlay;
    },

    updateDescription() {
      this.new_description = document.getElementById(`description${this.block_id}`).value;
    },

    updateNewUrl(new_url) {
      this.new_url = new_url;
    },

    emitEditBtn() {
      this.$emit('editBtnClicked');
      this.resetImageSelection();
    },

    emitMoveBtn(is_up) {
      this.$emit('moveBtnClicked', is_up);
    },

    emitConfirmBtn() {
      let value = {};
      if(this.mediablock.type == 'youtube') {
        // we only update new information!
        if(this.new_url != '' && this.new_url != this.mediablock.url) {
          value.url = this.new_url;
        }
        if(this.new_description != '' && this.new_description != this.mediablock.description) {
          value.description = this.new_description;
        }

        this.$emit('confirmBtnClicked', {
          action: 'leave_edit',
          value: value,
          type: this.mediablock.type,
        });
      }
      else if(this.mediablock.type == 'image') {
        // we only update new information!
        if(this.new_description != '' && this.new_description != this.mediablock.description) {
          value.description = this.new_description;
        }

        this.$emit('confirmBtnClicked', {
          action: 'leave_edit',
          value: value,
          type: this.mediablock.type,
          img: {
            is_upload: !!this.new_image.local_url,
            file: this.new_image.file,
            local_url: this.new_image.local_url,
          },
        });
      }
    },

    emitCancelBtn() {
      this.resetImageSelection();
      this.$emit('cancelBtnClicked')
    },

    emitDeleteBtn() {
      this.$emit('deleteBtnClicked');
    },
  },
}
</script>

<style lang="css" scoped>
.tool-buttons {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
}
.media-block-component {

}
.media-block-img-center {
  text-align: center;
}
.media-block-img {
  display: inline-block;
}
.media-block-overlay-img {
  padding: 10px;
}
.media-block-overlay-img > .v-img {
  width: 100%;
  height: auto;
}
.media-block-description {
  color: white;
  text-align: right;
  font-weight: 200;
  font-style: italic;
  font-size: var(--p-font-size-media);
}
.media-block-video > .media-block-description {
  padding-top: 4px;
}
.media-block-video {
  padding: 0 5%;
}
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.video-container-placeholder {
  position: relative;
  width: 100%;
  height: calc(70vw * 0.5625);
  background-color: #222;
  border-radius: 25px;
  border-style: dashed;
  border-width: 1px;
  border-color: grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image-container-placeholder {
  position: relative;
  width: 100%;
  height: 67%;
  background-color: #222;
  border-radius: 25px;
  border-style: dashed;
  border-width: 1px;
  border-color: grey;
  padding: 60px 120px;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
iframe {
  width: 100%;
  height: 100%;
}
img {
  max-width: 100%;
  max-height: 800px;
  cursor: pointer;
}

/* self growing textarea */
/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */

.textblock-grow-wrap {
  display: grid;
}
.textblock-grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.textblock-grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.textblock-grow-wrap > textarea,
.textblock-grow-wrap::after {
  outline-color: grey;
  outline-style: dashed;
  outline-width: thin;
  padding: 0px;
  font: inherit;

  grid-area: 1 / 1 / 2 / 2;
}
</style>
