<template>
  <div>
    <EditBtn
      v-if="has_permission_edit"
      :editing="editing"
      @editBtnClicked="emitEditBtn()"
    />
    <div
      v-if="editing && has_permission_edit"
      class="tool-buttons"
    >
      <MoveBtn
        v-if="has_permission_edit"
        @moveBtnClicked="emitMoveBtn($event)"
      />
      <ConfirmBtn
        v-if="has_permission_edit"
        @confirmBtnClicked="initConfirm()"
      />
      <CancelBtn
        v-if="has_permission_edit"
        @cancelBtnClicked="emitCancelBtn()"
      />
      <DeleteBtn
        v-if="has_permission_edit"
        @deleteBtnClicked="emitDeleteBtn()"
      />

      <!-- Table Toggle -->
      <div v-if="has_permission_edit && editing">
        <v-btn dark outlined
          @click="is_table_mode = !is_table_mode"
          :class="{ 'is-active': is_table_mode, 'table-btn': true }"
          :height="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
          :width="$vuetify.breakpoint.smAndDown ?  '24px' : '40px'"
        >
          <v-icon>
            mdi-table
          </v-icon>
        </v-btn>
      </div>
    </div>
    <Tiptap
      v-if="editing && has_permission_edit"
      :initial_content="textblock.text"
      :is_selected="is_selected"
      :is_table_mode="is_table_mode"
      :is_init_confirm="is_init_confirm"
      :is_init_cancel="is_init_cancel"
      @initConfirm="emitConfirmBtn($event.value)"
      @initCancel="emitCancelBtn()"
    />
    <p v-else class="textblock-p" v-html="textblock.text" />
  </div>
</template>

<script>
import EditBtn from '@/components/Articles/Buttons/EditBtn.component.vue';
import MoveBtn from '@/components/Articles/Buttons/MoveBtn.component.vue';
import ConfirmBtn from '@/components/Articles/Buttons/ConfirmBtn.component.vue';
import CancelBtn from '@/components/Articles/Buttons/CancelBtn.component.vue';
import DeleteBtn from '@/components/Articles/Buttons/DeleteBtn.component.vue';
import Tiptap from '@/components/Tiptap.component.vue';

export default {
  components: {
    EditBtn,
    MoveBtn,
    ConfirmBtn,
    CancelBtn,
    DeleteBtn,
    Tiptap,
  },

  props: [
    'textblock',
    'has_permission_edit',
    'editing',
    'block_id',
    'is_selected',
  ],

  data: () => ({
    is_init_confirm: false,
    is_init_cancel: false,
    is_table_mode: false,
  }),

  methods: {
    emitEditBtn() {
      this.$emit('editBtnClicked');
    },

    emitMoveBtn(is_up) {
      this.$emit('moveBtnClicked', is_up);
    },

    initConfirm() {
      this.is_init_confirm = true;
    },

    emitConfirmBtn(html_text) {
      this.is_init_confirm = false;
      let value = {};

      if(html_text != '' && html_text != this.textblock.text) {
        if(html_text.substring(0, 6) === '<table') {
          html_text = this.fixTableResponsiveness(html_text);
        }

        value.text = html_text;
      }

      this.$emit('confirmBtnClicked', { value: value });
    },

    initCancel() {
      this.is_init_cancel = true;
    },

    emitCancelBtn() {
      this.is_init_cancel = false;
      this.$emit('cancelBtnClicked')
    },

    emitDeleteBtn() {
      this.$emit('deleteBtnClicked');
    },

    fixTableResponsiveness(table_string){
      let temp = table_string.split('<tr>') //wir splitten erstmal um jede tableRow, dabei können wir temp[0] verwerfen (<table><tbody>)
      table_string =
        '<table class="textblock-table">' + //unser "neues" temp[0]
        '<thead class="table-thead"><tr>' + //unser thead tag mit der entsprechenden css-klasse aus App.vue
        temp[1] + //unsere eigentliche header-row
        '</thead><tbody>' //closing </thead> tag und ersatz für in temp[0] verworfenes <tbody> tag
      ;
      for(let i = 2; i < temp.length; i++) { //nun kümmern wir uns um alle folgenden tableRows
        table_string += '<tr>' + temp[i]; //da wir zuvor mit <tr> gesplittet haben, fügen wir das tag jeweils wieder an
      }

      let header = table_string.split('</th>'); //wir schneiden bei jedem tableHeader closing tag ab
      let rows = header.splice(header.length - 1, 1); //wenn wir das letzte mal gesplittet haben, folgen keine tableHeader mehr
      let s = table_string.split('<td ')[0]; //s ist unser return wert! alles vor dem ersten tableData wollen wir behalten

      for(let i = 0; i < header.length; i++) {
        // header[i] = header[i].match(/(?<=<p>).*(?=<\/p>)/); //wir isolieren die bezeichner der tableHeader in header
        let h;
        h = header[i].split('<p>')[1]; //zuerst werfen wir alles weg vor <p>
        h = h.split('</p>')[0]; //danach werfen wir alles weg nach </p>
        header[i] = h; //nur der tableHeader bleibt übrig
      }

      rows = rows[0].split('<td '); //rows ist gerade ein array mit nur einem wert, wir überschreiben es
      rows.splice(0, 1) //der erste wert ein leerer string und nicht brauchbar
      for(let i = 0; i < rows.length; i++) {
        rows[i] = '<td data-label="' + header[i % header.length] + '" ' + rows[i]; //wir ergänzen effektiv "<td" um ein data-label
        s += rows[i]; //und fügen es unserem rückgab string hinzu
      }
      return s;
    },
  },

  mounted() {
    this.is_table_mode = this.textblock.text.substring(0, 6) === '<table';
  },
}
</script>

<style lang="css" scoped>
.tool-buttons {
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
}
.table-btn {
  min-width: 0 !important;
  padding: 0 !important;
  letter-spacing: normal;
  text-transform: none;
}
.textblock-p {
  text-align: left;
  font-size: var(--p-font-size-article);
  font-weight: 300;
  color: white;
}
</style>
