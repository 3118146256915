<template>
  <div
    class="block-container"
    @mouseover="is_hovering=true"
    @mouseleave="is_hovering=false"
  >
    <transition name="fade">
      <div
        class="block-edit-line"
        v-if="is_hovering"
      >
        <div class="block-btn-container">
          <!-- Create Text Block -->
          <v-btn color="grey lighten-2" @click="$emit('createTextBlock')">
            <v-icon color="grey darken-3">
              mdi-text
            </v-icon>
          </v-btn>
          <!-- Create Image Block -->
          <v-btn color="amber darken-1" @click="$emit('createMediaBlockImage')">
            <v-icon color="white">
              mdi-image
            </v-icon>
          </v-btn>
          <!-- Create YouTube Block -->
          <v-btn color="red" @click="$emit('createMediaBlockVideo')">
            <v-icon color="white">
              mdi-youtube
            </v-icon>
          </v-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: [
    'category_id',
    'article_id',
  ],

  data: () => ({
    is_hovering: false,
  }),
}
</script>

<style lang="css" scoped>
.block-container {
  z-index: 1;
  height: 60px;
  width: 100%;
}
.block-edit-line {
  transform: translateY(30px);
  border-style: dashed;
  border-color: grey;
  border-width: 0 0 1px 0;
}
.block-btn-container {
  position: absolute;
  transform: translateY(-50%);
  display: flex;
  grid-column-gap: 10px;
  justify-content: center;
  width: 100%;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
.fade-enter-active, .fade-leave-active {
  transition: .25s ease;
}

</style>
